import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, Route, Routes } from "react-router-dom";
import { userDetails } from "../store/authSlice";
import { Spinner } from "react-bootstrap";
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ routes }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        const checkAuthenticated = async () => {
            dispatch(userDetails()).then(res => {
                if (res.payload === undefined) {
                    navigate('/');
                } else {
                    if (res.payload.data.success === false) {
                        navigate('/');
                    }
                }
            });
        }

        checkAuthenticated();
    }, [dispatch,navigate]);

    return (isAuthenticated === true) ? (
        <Routes>
            {routes.map(({ path, element }, index) => (
                <Route key={index} path={path} element={element} />
            ))}
        </Routes>
    ) : <div className="loading show"> <Spinner className="loader" animation="grow" variant="primary" /> </div>;
};

export default ProtectedRoute;