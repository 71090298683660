import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {AuthProvider} from './context/AuthContext';

// middlewares
import ProtectedRoute from "./components/ProtectedRoute";


// Pages List
import Login from "./container/pages/auth/Login";
import Dashboard from "./container/pages/Dashboard";
import EmployeeList from "./container/pages/employee/List";
import AddEmployee from "./container/pages/employee/Add";
import EditEmployee from "./container/pages/employee/Edit";
import CategoryList from "./container/pages/category/List";
import AddCategory from "./container/pages/category/Add";
import EditCategory from "./container/pages/category/Edit";
import ProductList from "./container/pages/product/List";
import AddProduct from "./container/pages/product/Add";
import EditProduct from "./container/pages/product/Edit";
import RentProduct from "./container/pages/product_rental/RentProduct";
import RentalList from "./container/pages/product_rental/List";
import RentalProductReturn from "./container/pages/product_rental/RentalProductReturn";
import CustomerList from "./container/pages/customer/List";
import AddCustomer from "./container/pages/customer/Add";
import EditCustomer from "./container/pages/customer/Edit";

function App() {
    const protectedRoutes = [
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/employee", element: <EmployeeList /> },
        { path: "/employee/new", element: <AddEmployee /> },
        { path: "/employee/edit/:id", element: <EditEmployee /> },
        { path: "/category", element: <CategoryList /> },
        { path: "/category/new", element: <AddCategory /> },
        { path: "/category/edit/:id", element: <EditCategory /> },
        { path: "/product", element: <ProductList /> },
        { path: "/product/new", element: <AddProduct /> },
        { path: "/product/edit/:id", element: <EditProduct /> },
        { path: "/rental", element: <RentalList /> },
        { path: "/rental/new", element: <RentProduct /> },
        { path: "/rental/return/:id", element: <RentalProductReturn /> },
        { path: "/customer", element: <CustomerList /> },
        { path: "/customer/new", element: <AddCustomer /> },
        { path: "/customer/edit/:id", element: <EditCustomer /> },
    ];

    return (
        <div className="App">
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={<Login/>}/>
                        <Route path="/*" element={<ProtectedRoute routes={protectedRoutes} />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
