import React, {useCallback, useEffect, useState} from "react";
import MasterLayout from "../layouts/master";
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import BarChart from '../../components/chart/BarChart';
import {userCounts} from "../../store/authSlice";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [count, setCount] = useState({});
    const [barChart,setBarChart] = useState({
        lables: [],
        data: [],
    });

    const getAllCounts = useCallback(async () => {
        try {
            const res = await dispatch(userCounts());
            if (res.payload.data.success) {
                setCount(res.payload.data.data.counts);
                const newLabels = [];
                const newData = [];

                res.payload.data.data.sales.forEach(sale => {
                    newLabels.push(sale.month);
                    newData.push(sale.total_sales);
                });

                setBarChart({
                    labels: newLabels,
                    data: newData
                });
            }
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);


    useEffect(() => {
        getAllCounts()
    }, [getAllCounts]);

    return (
        <>
            <MasterLayout>
                <div className="row">
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-gradient-cyan">
                            <div className="inner">
                                <h3>{count.total_employee | 0}</h3>
                                <p>Total Employees</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users"></i>
                            </div>
                            <NavLink to="/employee" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-gradient-olive">
                            <div className="inner">
                                <h3>{count.total_category | 0}</h3>
                                <p>Total Categories</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-th-list"></i>
                            </div>
                            <NavLink to="/category" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-gradient-red">
                            <div className="inner">
                                <h3>{count.total_product | 0}</h3>
                                <p>Total Products</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-barcode"></i>
                            </div>
                            <NavLink to="/product" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-gradient-teal">
                            <div className="inner">
                                <h3>{count.total_customer | 0}</h3>
                                <p>Total Customers</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users"></i>
                            </div>
                            <NavLink to="/customer" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-gradient-orange">
                            <div className="inner">
                                <h3>{count.total_rental | 0}</h3>
                                <p>Total Rentals</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-archive"></i>
                            </div>
                            <NavLink to="/rental" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header border-0">
                                <div className="d-flex justify-content-between">
                                    <h3 className="card-title">Sales</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="position-relative mb-4">
                                    <BarChart barChartData={barChart}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    )
}

export default Dashboard;
