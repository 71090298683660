import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosapi from '../utilities/axiosapi';

export const rentalProductList = createAsyncThunk(
    'rental/list',
    async (params) => {
        const response = await axiosapi.get('rental-product', { params });
        return response;
    }
);

export const rentalProductById = createAsyncThunk(
    'rental/show',
    async (rentalId) => {
        const response = await axiosapi.get(`rental-product/${rentalId}`);
        return response;
    }
);

export const returnRentalProductsById = createAsyncThunk(
    'rental/show',
    async (rentalId) => {
        const response = await axiosapi.put(`rental-product/${rentalId}`);
        return response;
    }
);

export const deleteRentalProductById = createAsyncThunk(
    'rental/delete',
    async (rentalId) => {
        const response = await axiosapi.delete(`rental-product/${rentalId}`);
        return response;
    }
);

export const productRentalSlice = createSlice({
    name: 'rentals',
    initialState: {
        rentals:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(rentalProductList.fulfilled,(state, { payload }) => {
            if(payload.data.success === true){
                state.rentals = payload.data.data.rentals;
            }
        });
        builder.addCase(rentalProductList.rejected, (state, { payload }) => {
            if(payload.data.success === false){
                state.rentals = {};
            }
        })
    }
})

export default productRentalSlice.reducer