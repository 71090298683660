import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import MasterLayout from '../../layouts/master';
import {rentalProductById, returnRentalProductsById} from "../../../store/productRentalSlice";

const RentalProductReturn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    const [notificationData, setNotificationData] = useState({});
    const [rentalProduct, setRentalProduct] = useState({});

    const getRentalProductDetail = useCallback(async (rentalId) => {
        try {
            const res = await dispatch(rentalProductById(rentalId));
            if (res.payload.data.success) {
                setRentalProduct(res.payload.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);

    const returnProducts = async () => {
        const res = await dispatch(returnRentalProductsById(id));
        if (res.payload.data.success) {
            setNotificationData({
                variant: 'success',
                message: res.payload.data.message,
                show: true
            });
            setTimeout(() => {
                setNotificationData({variant: "", message: "", show: false});
                navigate('/rental');
            }, process.env.REACT_APP_NOTIFICATION_TIME);
        } else {
            setNotificationData({
                variant: 'danger',
                message: res.payload.data.message,
                show: true
            });
            setTimeout(() => {
                setNotificationData({variant: "", message: "", show: false});
            }, process.env.REACT_APP_NOTIFICATION_TIME);
        }
    }

    useEffect(() => {
        getRentalProductDetail(id);
    }, [getRentalProductDetail, id]);

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="p-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Selected Customer</h3>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <tbody>
                                        {(Object.keys(rentalProduct).length > 0) ? (
                                            <>
                                                <tr>
                                                    <td width="20%"><label>First Name</label></td>
                                                    <td width="80%">{rentalProduct.customer.first_name || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><label>Last Name</label></td>
                                                    <td width="80%">{rentalProduct.customer.last_name || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><label>Email</label></td>
                                                    <td width="80%">{rentalProduct.customer.email || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><label>Mobile No</label></td>
                                                    <td width="80%">{rentalProduct.customer.mobile_no || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><label>Address</label></td>
                                                    <td width="80%">{rentalProduct.customer.address || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><label>Note</label></td>
                                                    <td width="80%">{rentalProduct.customer.note || '-'}</td>
                                                </tr>
                                            </>
                                        ) : <tr>
                                            <td colSpan={2}>No Customer Data</td>
                                        </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Selected Products</h3>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Product Image</th>
                                            <th>Product Name</th>
                                            <th>Rented Date</th>
                                            <th>Rented Days</th>
                                            <th>Product Rent</th>
                                            <th>Total Rent</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(Object.keys(rentalProduct).length > 0 && rentalProduct.items.length > 0) ? rentalProduct.items.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.product.product_image && (<img className="img-thumbnail"
                                                                                         src={`${process.env.REACT_APP_BACKEND_URL}/storage/products/` + item.product.product_image}
                                                                                         width="100" height="100"
                                                                                         alt="Product"/>)}</td>
                                                <td>{item.product.product_name}</td>
                                                <td>{new Date(item.start_date).toLocaleDateString()} to {new Date(item.end_date).toLocaleDateString()}</td>
                                                <td>{item.total_days}</td>
                                                <td>{item.product.rental_per_day}</td>
                                                <td>{item.total_rent}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={3}>No Data</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <button type="button" onClick={returnProducts} className="btn btn-success mr-5">Take Return</button>
                                    <NavLink className="btn btn-secondary" to="/rental">Cancel</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default RentalProductReturn;