import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('email is required'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('password is required'),
});

export const employeeSchema = Yup.object().shape({
    first_name: Yup.string().min(1, 'Minimum first name length is 1').max(50, 'Maximum first name length is 50').required('first name is required'),
    last_name: Yup.string().min(1, 'Minimum last name length is 1').max(50, 'Maximum last name length is 50').required('last name is required'),
    email: Yup.string().min(1, 'Minimum email length is 1').max(100, 'Maximum email length is 100').required('email is required'),
    mobile_no: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be exactly 10 digits').required('mobile is required'),
    salary: Yup.number().min(1, 'Minimum salary is 1').max(1000000, 'Maximum salary is 1000000').required('salary is required'),
});

export const categorySchema = Yup.object().shape({
    category_name: Yup.string().min(1, 'Minimum category name length is 1').max(50, 'Maximum category name length is 50').required('category name is required'),
});

export const productSchema = Yup.object().shape({
    product_name: Yup.string().min(1, 'Minimum product name length is 1').max(100, 'Maximum product name length is 100').required('product name is required'),
    description: Yup.string().min(1, 'Minimum product description length is 1').max(190, 'Maximum product description length is 190').required('product description is required'),
    category_id: Yup.number().required('category is required'),
    rental_per_day: Yup.number().min(1, 'Minimum rental per day is 1').max(1000000, 'Maximum rental per day is 1000000').required('rental per day is required').positive('Must be a positive number'),
    product_image: Yup.string().required('product image is required'),
});

export const newCustomerSchema = Yup.object().shape({
    first_name: Yup.string().min(1, 'Minimum first name length is 1').max(50, 'Maximum first name length is 50').required('first name is required'),
    last_name: Yup.string().min(1, 'Minimum last name length is 1').max(50, 'Maximum last name length is 50').required('last name is required'),
    email: Yup.string().min(1, 'Minimum email length is 1').max(100, 'Maximum email length is 100').required('email is required'),
    mobile_no: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be exactly 10 digits').required('mobile is required'),
    address: Yup.string().min(1, 'Minimum address is 1').max(190, 'Maximum address length is 190').required('address is required'),
    note: Yup.string().min(1, 'Minimum note is 1').max(190, 'Maximum note length is 100'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('password is required'),
    password_confirmation: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('confirm password is required'),
});

export const editCustomerSchema = Yup.object().shape({
    first_name: Yup.string().min(1, 'Minimum first name length is 1').max(50, 'Maximum first name length is 50').required('first name is required'),
    last_name: Yup.string().min(1, 'Minimum last name length is 1').max(50, 'Maximum last name length is 50').required('last name is required'),
    email: Yup.string().min(1, 'Minimum email length is 1').max(100, 'Maximum email length is 100').required('email is required'),
    mobile_no: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be exactly 10 digits').required('mobile is required'),
    address: Yup.string().min(1, 'Minimum address is 1').max(190, 'Maximum address length is 190').required('address is required'),
    note: Yup.string().min(1, 'Minimum note is 1').max(190, 'Maximum note length is 100')
});