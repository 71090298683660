import React, {useState, useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import MasterLayout from '../../layouts/master';
import {employeeSchema} from "../../../schemas/validationSchemas";
import {storeEmployee} from "../../../store/employeeSlice";
import {employeeRoleList} from "../../../store/employeeRoleSlice";

const Add = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const [notificationData, setNotificationData] = useState({});

    const getRoles = useCallback(async () => {
        try {
            const res = await dispatch(employeeRoleList());
            if (res.payload.data.success) {
                setRoles(res.payload.data.data);
            }
        } catch(err) {
            console.log(err);
        }
    },[dispatch]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Add New Employee</h3>
                            </div>
                            <Formik
                                initialValues={{
                                    id: '',
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    mobile_no: '',
                                    salary: '',
                                    role_id: '',
                                    password: '',
                                    password_confirmation: '',
                                }}
                                validationSchema={employeeSchema}
                                onSubmit={async (values,{ setSubmitting, setErrors }) => {
                                    const formData = new FormData();
                                    formData.append('id',values.id);
                                    formData.append('first_name',values.first_name);
                                    formData.append('last_name',values.last_name);
                                    formData.append('email',values.email);
                                    formData.append('mobile_no',values.mobile_no);
                                    formData.append('salary',values.salary);
                                    formData.append('role_id',values.role_id);
                                    formData.append('password',values.password);
                                    formData.append('password_confirmation',values.password_confirmation);

                                    try {
                                        const res = await dispatch(storeEmployee(formData));
                                        if (res.payload.data.success) {
                                            navigate('/employee');
                                        } else {
                                            setNotificationData({
                                                variant: 'danger',
                                                message: res.payload.data.message,
                                                show: true
                                            });
                                            setErrors(res.payload.data.data);
                                            setTimeout(() => {
                                                setNotificationData({variant: "", message: "", show: false});
                                            }, process.env.REACT_APP_NOTIFICATION_TIME);
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({errors, touched, setFieldValue}) => (
                                    <Form method="post">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="first-name">First Name</label>
                                                <Field type="text" name="first_name" className="form-control" id="first-name" placeholder="Enter First Name" />
                                                {errors.first_name && touched.first_name ? (<span className="text-danger">{errors.first_name}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="last-name">Last Name</label>
                                                <Field type="text" name="last_name" className="form-control" id="last-name" placeholder="Enter Last Name" />
                                                {errors.last_name && touched.last_name ? (<span className="text-danger">{errors.last_name}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field type="email" name="email" className="form-control" id="email" placeholder="Enter Email" />
                                                {errors.email && touched.email ? (<span className="text-danger">{errors.email}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="mobile_no">Mobile</label>
                                                <Field type="number" name="mobile_no" className="form-control" id="mobile_no" placeholder="Enter Mobile" />
                                                {errors.mobile_no && touched.mobile_no ? (<span className="text-danger">{errors.mobile_no}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="salary">Salary</label>
                                                <Field type="number" name="salary" className="form-control" id="salary" placeholder="Enter Salary" />
                                                {errors.salary && touched.salary ? (<span className="text-danger">{errors.salary}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="employee-role">Role</label>
                                                <Field as="select" name="role_id" className="form-control" id="employee-role">
                                                    <option value="">select</option>
                                                    {roles.length > 0 && roles.map((r,index) => (
                                                        <option key={index} value={r.id}>{r.name}</option>
                                                    ))}
                                                </Field>
                                                {errors.role_id && touched.role_id ? (<span className="text-danger">{errors.role_id}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <Field type="password" name="password" className="form-control" id="password" placeholder="Enter Password" />
                                                {errors.password && touched.password ? (<span className="text-danger">{errors.password}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password_confirmation">Confirm Password</label>
                                                <Field type="password" name="password_confirmation" className="form-control" id="password_confirmation" placeholder="Enter Confirm Password" />
                                                {errors.password_confirmation && touched.password_confirmation ? (<span className="text-danger">{errors.password_confirmation}</span>) : null}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary mr-5">Submit</button>
                                            <NavLink className="btn btn-secondary" to="/employee">Cancel</NavLink>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default Add;