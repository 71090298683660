import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {rentalProductList, deleteRentalProductById} from "../../../store/productRentalSlice";
import MasterLayout from "../../layouts/master";
import {Link, NavLink} from "react-router-dom";

const List = () => {

    const dispatch = useDispatch();
    const [params,setParams] = useState({ search:"", page:1, limit:10, total_pages:0 });
    const rentals = useSelector((state) => state.productRental.rentals);
    const [previousPage,setPreviousPage] = useState(true);
    const [nextPage,setNextPage] = useState(true);
    const [notificationData,setNotificationData] = useState();

    const getRentalProductList = useCallback((current_page = 1) => {
        const updatedParams = { ...params, page: current_page };
        dispatch(rentalProductList(updatedParams)).then((res) => {
            if(res.payload.data.success) {
                let totalPages = Math.ceil(res.payload.data.data.total_records / updatedParams.limit);
                params.total_pages = totalPages;
                setNextPage(updatedParams.page >= totalPages);
                setPreviousPage(updatedParams.page <= 1);
            }
        }).catch((err) => {
            console.log(err);
        });
    },[dispatch,params]);

    const currentPage = useCallback((current_page) => {
        getRentalProductList(current_page);
    },[getRentalProductList]);

    const searchData = useCallback((event) => {
        if (event.keyCode === 13) {
            getRentalProductList();
        }
    },[getRentalProductList]);

    const deleteRentalProduct = useCallback((id) => {
        dispatch(deleteRentalProductById(id)).then((res) => {
            getRentalProductList();
            setNotificationData({variant: 'success', message: res.payload.data.message, show: true});
            setTimeout( ()=>{
                setNotificationData({
                    variant: "",
                    message: "",
                    show: false,
                });
            },process.env.REACT_APP_NOTIFICATION_TIME);
        }).catch((err) =>{
            console.log(err);
        });
    },[dispatch,getRentalProductList]);


    useEffect(() => {
        getRentalProductList();
    },[getRentalProductList]);

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <NavLink className="btn btn-success btn-sm" to="/rental/new">
                                            <i className="fas fa-plus"></i>
                                        </NavLink>
                                    </h3>
                                    <div className="card-tools w-25">
                                        <div className="input-group input-group-sm p-1">
                                            <input type="text" name="search" className="form-control float-right" placeholder="Search" value={params.search} onChange={(e) => setParams({...params, search: e.target.value })} onKeyDown={searchData}/>
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-info">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr className="text-capitalize">
                                            <th width="5%">#</th>
                                            <th width="10%">invoice no</th>
                                            <th width="10%">total</th>
                                            <th width="10%">customer</th>
                                            <th width="10%">email</th>
                                            <th width="10%">mobile no</th>
                                            <th width="10%">address</th>
                                            <th width="10%">note</th>
                                            <th width="10%">status</th>
                                            <th width="15%">actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(rentals.length > 0) ? rentals.map((r,index) => (
                                            <tr key={index}>
                                                <th>{index+1}</th>
                                                <td>{r.invoice_no}</td>
                                                <td>{r.total}</td>
                                                <td>{r.customer.last_name} {r.customer.first_name}</td>
                                                <td>{r.customer.email}</td>
                                                <td>{r.customer.mobile_no}</td>
                                                <td>{r.customer.address}</td>
                                                <td>{r.customer.note || '-'}</td>
                                                <td>{r.status === 1 ? (<div className="badge badge-info text-capitalize p-2 rounded-5">active</div>) : (<div className="badge badge-success text-capitalize p-2 rounded-5">completed</div>)}</td>
                                                <td>{r.status === 1 ? (<Link className="btn btn-info rounded-5 mr-2" to={`/rental/return/${r.id}`}>Return</Link>) : (<button onClick={()=> {deleteRentalProduct(r.id)}} className="btn btn-danger rounded-5">Delete</button>)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="8">No Data</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                {rentals.length > 0 && (
                                    <div className="card-footer clearfix">
                                        <ul className="pagination pagination-sm m-0 float-right">
                                            <li className="page-item">
                                                <button type="button" onClick={() => currentPage(params.page-1)} disabled={previousPage} className="page-link">&laquo;</button>
                                            </li>
                                            {(() => {
                                                const arr = [];
                                                for (let i = 1; i <= params.total_pages; i++) {
                                                    arr.push(<li className="page-item" key={i}> <button disabled={params.page === i} className={(params.page === i ? 'page-link disabled' : 'page-link')} onClick={() => currentPage(i)}>{i}</button></li>);
                                                }
                                                return arr;
                                            })()}
                                            <li className="page-item">
                                                <button type="button" onClick={() => currentPage(params.page+1)} disabled={nextPage} className="page-link">&raquo;</button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default List;