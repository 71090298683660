import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosapi from '../utilities/axiosapi';

export const productList = createAsyncThunk(
    'product/list',
    async (params) => {
        const response = await axiosapi.get('product', { params });
        return response;
    }
);

export const storeProduct = createAsyncThunk(
    'product/store',
    async (productDetails) => {
        const response = await axiosapi.post('product',productDetails,{ headers: { "Content-Type": "multipart/form-data", "Accept": "application/json" } });
        return response;
    }
);

export const productById = createAsyncThunk(
    'product/show',
    async (productId) => {
        const response = await axiosapi.get(`product/${productId}`);
        return response;
    }
);

export const updateProduct = createAsyncThunk(
    'product/update',
    async (productDetails) => {
        const response = await axiosapi.post(`product/${productDetails.get('id')}`, productDetails, { headers: { "Content-Type": "multipart/form-data", "Accept": "application/json" } });
        return response.data;
    }
);

export const deleteProductById = createAsyncThunk(
    'product/delete',
    async (productId) => {
        const response = await axiosapi.delete(`product/${productId}`);
        return response;
    }
);

export const rentProducts = createAsyncThunk(
    'product/rent',
    async (rentProductsDetails) => {
        const response = await axiosapi.post('rental-product',rentProductsDetails);
        return response;
    }
);

export const productAllList = createAsyncThunk(
    'product/all',
    async (params) => {
        const response = await axiosapi.get('products', { params });
        return response;
    }
);

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        products:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(productList.fulfilled,(state, { payload }) => {
            if(payload.data.success === true){
                state.products = payload.data.data.products;
            }
        });
        builder.addCase(productList.rejected, (state, { payload }) => {
            if(payload.data.success === false){
                state.products = {};
            }
        })
    }
})

export default productSlice.reducer