import React, {useState, useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import MasterLayout from '../../layouts/master';
import {productSchema} from "../../../schemas/validationSchemas";
import {storeProduct} from "../../../store/productSlice";
import { categoryAllList } from "../../../store/categorySlice";

const Add = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notificationData, setNotificationData] = useState({});
    const [categories, setCategories] = useState([]);

    const getCategories = useCallback(async () => {
        try {
            const res = await dispatch(categoryAllList());
            if (res.payload.data.success) {
                setCategories(res.payload.data.data);
            }
        } catch(err) {
            console.log(err);
        }
    },[dispatch]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Add New Product</h3>
                            </div>
                            <Formik
                                initialValues={{
                                    id: '',
                                    product_name: '',
                                    description: '',
                                    category_id: '',
                                    rental_per_day: '',
                                    product_image: ''
                                }}
                                validationSchema={productSchema}
                                onSubmit={async (values,{ setSubmitting, setErrors }) => {
                                    const formData = new FormData();
                                    formData.append('id',values.id);
                                    formData.append('product_name',values.product_name);
                                    formData.append('description',values.description);
                                    formData.append('category_id',values.category_id);
                                    formData.append('rental_per_day',values.rental_per_day);
                                    if (values.product_image instanceof File) {
                                        formData.append('product_image', values.product_image);
                                    }

                                    try {
                                        const res = await dispatch(storeProduct(formData));
                                        if (res.payload.data.success) {
                                            navigate('/product');
                                        } else {
                                            setNotificationData({
                                                variant: 'danger',
                                                message: res.payload.data.message,
                                                show: true
                                            });
                                            setErrors(res.payload.data.data);
                                            setTimeout(() => {
                                                setNotificationData({variant: "", message: "", show: false});
                                            }, process.env.REACT_APP_NOTIFICATION_TIME);
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({errors, touched, setFieldValue}) => (
                                    <Form method="post">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="product-name">Product Name</label>
                                                <Field type="text" name="product_name" className="form-control" id="product-name" placeholder="Enter Product Name" />
                                                {errors.product_name && touched.product_name ? (<span className="text-danger">{errors.product_name}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <Field type="text" name="description" className="form-control" id="description" placeholder="Enter Description" />
                                                {errors.description && touched.description ? (<span className="text-danger">{errors.description}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="category">Category</label>
                                                <Field as="select" name="category_id" className="form-control" id="category">
                                                    <option value="">select</option>
                                                    {categories.length > 0 && categories.map((c,index) => (
                                                        <option key={index} value={c.id}>{c.category_name}</option>
                                                    ))}
                                                </Field>
                                                {errors.category_id && touched.category_id ? (<span className="text-danger">{errors.category_id}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="rental_per_day">Rental Per Day</label>
                                                <Field type="number" name="rental_per_day" className="form-control" id="rental_per_day" placeholder="Enter Rental Per Day" min={1}/>
                                                {errors.rental_per_day && touched.rental_per_day ? (<span className="text-danger">{errors.rental_per_day}</span>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="product_image">Product Image</label>
                                                <input type="file" name="product_image" className="form-control" id="product_image" onChange={(event) => { setFieldValue("product_image", event.currentTarget.files[0]); }} />
                                                {errors.product_image && touched.product_image ? (<span className="text-danger">{errors.product_image}</span>) : null}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary mr-5">Submit</button>
                                            <NavLink className="btn btn-secondary" to="/product">Cancel</NavLink>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default Add;