import { configureStore } from '@reduxjs/toolkit'

/* all reducer */
import authReducer from './authSlice';
import employeeReducer from './employeeSlice';
import employeeRoleReducer from './employeeRoleSlice';
import categoryReducer from './categorySlice';
import productReducer from './productSlice';
import customerReducer from './customerSlice';
import productRentalReducer from './productRentalSlice';

/* store setup */
export default configureStore({
    reducer: {
        auth: authReducer,
        employee: employeeReducer,
        employeeRole: employeeRoleReducer,
        category: categoryReducer,
        product: productReducer,
        customer: customerReducer,
        productRental: productRentalReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})