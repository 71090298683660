import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import MasterLayout from '../../layouts/master';
import AsyncSelect from 'react-select/async';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import {productAllList, rentProducts} from "../../../store/productSlice";
import {customerAllList} from "../../../store/customerSlice";

const RentProduct = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notificationData, setNotificationData] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        mobile_no: null,
        address: null,
        note: null
    });
    const [selectedProducts, setSelectedProducts] = useState([]);
    const sum = selectedProducts.reduce((sp, item) => parseFloat(sp) + parseFloat(item.total_rent), 0);

    const handleProductChange = (option) => {
        setSelectedProducts(p => {
            const exists = p.some(pr => pr.id === option.details.id);
            if (!exists) {
                return [...p, {
                    ...option.details,
                    start_date: new Date(),
                    end_date: new Date(),
                    total_days: 1,
                    total_rent: option.details.rental_per_day
                }];
            } else {
                return p;
            }
        });
    };

    const loadProducts = async (search,callback) => {
        if (!search) {
            return callback([]);
        }

        try {
            const response = await dispatch(productAllList({search: search}));
            if (response.payload.data.success) {
                const options = response.payload.data.data.map(product => ({value: product.id, label: product.product_name, details: product}));
                callback(options);
            } else {
                callback([]);
            }
        } catch (error) {
            setNotificationData({variant: 'success', message: error, show: true});
            setTimeout( ()=>{
                setNotificationData({
                    variant: "",
                    message: "",
                    show: false
                });
            },process.env.REACT_APP_NOTIFICATION_TIME);
            callback([]);
        }
    };

    const handleDateChangeEvent = (event, picker) => {
        let productId = parseInt(event.target.getAttribute('productid'));

        setSelectedProducts(products => {
            return products.map((product) => {
               if(product.id === productId){
                   let totaldays = picker.endDate.diff(picker.startDate, "days")+1;
                   return {...product,
                       start_date: picker.startDate.toString(),
                       end_date: picker.endDate.toString(),
                       total_days: totaldays,
                       total_rent: totaldays * parseFloat(product.rental_per_day),
                   }
               } else {
                   return product;
               }
            })
        })
    };

    const handleProductDelete = (event) => {
        let productId = parseInt(event.target.getAttribute('productid'));
        setSelectedProducts(products => { return products.filter(product => product.id !== productId) });
    }

    const handleCustomerChange = (customer) => {
        setSelectedCustomer({
            id: customer.details.id,
            first_name: customer.details.first_name,
            last_name: customer.details.last_name,
            email: customer.details.email,
            mobile_no: customer.details.mobile_no,
            address: customer.details.address,
            note: customer.details.note
        });
    };

    const loadCustomers = async (search,callback) => {
        if (!search) {
            return callback([]);
        }

        try {
            const response = await dispatch(customerAllList({search: search}));
            if (response.payload.data.success) {
                const options = response.payload.data.data.map(customer => ({value: customer.id, label: `${customer.first_name} ${customer.last_name}`, details: customer}));
                callback(options);
            } else {
                callback([]);
            }
        } catch (error) {
            setNotificationData({variant: 'success', message: error, show: true});
            setTimeout( ()=>{
                setNotificationData({
                    variant: "",
                    message: "",
                    show: false
                });
            },process.env.REACT_APP_NOTIFICATION_TIME);
            callback([]);
        }
    };

    const handleCustomerRemove = () => {
        setSelectedCustomer({});
    }

    const handleSubmit = async () => {
        const res = await dispatch(rentProducts({customer: selectedCustomer,products: selectedProducts, total: sum}));
        if (res.payload.data.success) {
            setNotificationData({
                variant: 'success',
                message: res.payload.data.message,
                show: true
            });
            setTimeout(() => {
                setNotificationData({variant: "", message: "", show: false});
                navigate('/rental');
            }, process.env.REACT_APP_NOTIFICATION_TIME);
        } else {
            setNotificationData({
                variant: 'danger',
                message: res.payload.data.message,
                show: true
            });
            setTimeout(() => {
                setNotificationData({variant: "", message: "", show: false});
            }, process.env.REACT_APP_NOTIFICATION_TIME);
        }
    }

    const areInputsFilled = () => {
        return selectedCustomer.first_name && selectedCustomer.last_name && selectedCustomer.email && selectedCustomer.mobile_no && selectedCustomer.address && selectedProducts.length > 0;
    };

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="p-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Customer</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="select-product-name">Select Customer</label>
                                        <AsyncSelect cacheOptions loadOptions={loadCustomers} defaultOptions onInputChange={(inputValue) => loadCustomers(inputValue, (options) => options)} onChange={handleCustomerChange} value="" placeholder="Search for a customer"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Products</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="select-product-name">Select Product Name</label>
                                        <AsyncSelect cacheOptions loadOptions={loadProducts} defaultOptions onInputChange={(inputValue) => loadProducts(inputValue, (options) => options)} onChange={handleProductChange} value="" placeholder="Search for a product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Selected Customer</h3>
                                    <div className="card-tools">
                                        <button onClick={handleCustomerRemove} type="button" className="btn btn-tool" title="Remove Customer">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td width="20%"><label>First Name</label></td>
                                                <td width="80%"><input type="text" name="first_name" className="form-control" defaultValue={selectedCustomer.first_name} onChange={(e) => setSelectedCustomer({...selectedCustomer,first_name: e.target.value})} /></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><label>Last Name</label></td>
                                                <td width="80%"><input type="text" name="last_name" className="form-control" defaultValue={selectedCustomer.last_name} onChange={(e) => setSelectedCustomer({...selectedCustomer,last_name: e.target.value})} /></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><label>Email</label></td>
                                                <td width="80%"><input type="email" name="email" className="form-control" defaultValue={selectedCustomer.email} onChange={(e) => setSelectedCustomer({...selectedCustomer,email: e.target.value})} /></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><label>Mobile No</label></td>
                                                <td width="80%"><input type="number" name="mobile_no" className="form-control" defaultValue={selectedCustomer.mobile_no} onChange={(e) => setSelectedCustomer({...selectedCustomer,mobile_no: e.target.value})} /></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><label>Address</label></td>
                                                <td width="80%"><input type="text" name="address" className="form-control" defaultValue={selectedCustomer.address} onChange={(e) => setSelectedCustomer({...selectedCustomer,address: e.target.value})} /></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><label>Note</label></td>
                                                <td width="80%"><input type="text" name="note" className="form-control" defaultValue={selectedCustomer.note} onChange={(e) => setSelectedCustomer({...selectedCustomer,note: e.target.value})} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Selected Products</h3>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Product Image</th>
                                                <th>Product Name</th>
                                                <th>Rented Date</th>
                                                <th>Rented Days</th>
                                                <th>Product Rent</th>
                                                <th>Total Rent</th>
                                                <th>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(selectedProducts.length > 0) ? selectedProducts.map((p,index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{p.product_image && (<img className="img-thumbnail" src={`${process.env.REACT_APP_BACKEND_URL}/storage/products/` + p.product_image} width="100" height="100" alt="Product" />)}</td>
                                                <td>{p.product_name}</td>
                                                <td>
                                                    <DateRangePicker initialSettings={{ minDate: new Date(), startDate: new Date(), endDate: new Date(), locale: {format: 'DD/MM/YYYY'}}} onEvent={handleDateChangeEvent}>
                                                        <input productid={p.id} type="text" defaultValue={p.rent_date} className="form-control form-control-sm w-75" />
                                                    </DateRangePicker>
                                                </td>
                                                <td>{p.total_days}</td>
                                                <td>{p.rental_per_day}</td>
                                                <td>{p.total_rent}</td>
                                                <td>
                                                    <div className="btn-group btn-group-sm">
                                                        <button productid={p.id} onClick={handleProductDelete} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={3}>No Data</td>
                                            </tr>
                                        )}
                                        </tbody>
                                        {(selectedProducts.length > 0) && (
                                            <tfoot>
                                                <tr>
                                                    <th colSpan={6}>Total</th>
                                                    <th>{sum}</th>
                                                </tr>
                                            </tfoot>
                                        )}
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <button onClick={handleSubmit} type="button" className="btn btn-success mr-5" disabled={!areInputsFilled()}>Book Order</button>
                                    <NavLink className="btn btn-secondary" to="/rental">Cancel</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default RentProduct;