import React from "react";

const AuthLayout = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

export default AuthLayout;