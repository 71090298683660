import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosapi from '../utilities/axiosapi';

export const categoryList = createAsyncThunk(
    'category/list',
    async (params) => {
        const response = await axiosapi.get('category', { params });
        return response;
    }
)

export const storeCategory = createAsyncThunk(
    'category/store',
    async (categoryDetails) => {
        const response = await axiosapi.post('category',categoryDetails);
        return response;
    }
)

export const categoryById = createAsyncThunk(
    'category/show',
    async (categoryId) => {
        const response = await axiosapi.get(`category/${categoryId}`);
        return response;
    }
)

export const updateCategory = createAsyncThunk(
    'category/update',
    async (categoryDetails) => {
        const response = await axiosapi.post(`category/${categoryDetails.get('id')}`, categoryDetails);
        return response.data;
    }
);

export const deleteCategoryById = createAsyncThunk(
    'category/delete',
    async (categoryId) => {
        const response = await axiosapi.delete(`category/${categoryId}`);
        return response;
    }
);

export const categoryAllList = createAsyncThunk(
    'category/all',
    async () => {
        const response = await axiosapi.get('categories');
        return response;
    }
);

export const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categories:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(categoryList.fulfilled,(state, { payload }) => {
            if(payload.data.success === true){
                state.categories = payload.data.data.categories;
            }
        });
        builder.addCase(categoryList.rejected, (state, { payload }) => {
            if(payload.data.success === false){
                state.categories = {};
            }
        })
    }
})

export default categorySlice.reducer