export function pathString(str) {
    return str.replace('/','');
}

export function currentDate() {
    return new Date().toISOString().split('T')[0];
}

export function replaceNullsWithEmptyStrings(obj) {
    for (let key in obj) {
        if (obj[key] === null) {
            obj[key] = "";
        }
    }
    return obj;
}

export function formatDate(date) {
    // Define arrays for day and month names
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get day of the week, month, day of the month, year, and time components
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Get timezone offset in hours and minutes
    const timezoneOffset = date.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60)).toString().padStart(2, '0');
    const offsetMinutes = Math.abs(timezoneOffset % 60).toString().padStart(2, '0');
    const offsetSign = timezoneOffset <= 0 ? '+' : '-';

    const timezoneString = `GMT${offsetSign}${offsetHours}${offsetMinutes}`;

    // Construct the formatted date string
    return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds} ${timezoneString}`;
}

export function addDaysToDate(date,days) {
    date.setDate(date.getDate() + days);
    return date;
}

