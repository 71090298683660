import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({barChartData}) => {
    const data = {
        labels: barChartData.labels,
        datasets: [
            {
                label: 'Sales',
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                data: barChartData.data,
                borderWidth: 1,
            }
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
