import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import MasterLayout from '../../layouts/master';
import {categorySchema} from "../../../schemas/validationSchemas";
import {categoryById, updateCategory} from "../../../store/categorySlice";

const Edit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    const [notificationData, setNotificationData] = useState({});
    const [category, setCategory] = useState({
        id: '',
        category_name: '',
    });

    const getCategoryDetail = useCallback(async (categoryId) => {
        try {
            const res = await dispatch(categoryById(categoryId));
            if (res.payload.data.success) {
                setCategory(res.payload.data.data);
            }
        } catch(err) {
            console.log(err);
        }
    },[dispatch]);


    useEffect(() => {
        getCategoryDetail(id)
    }, [getCategoryDetail,id]);

    return (
        <>
            <MasterLayout notificationData={notificationData}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Edit Category</h3>
                            </div>
                            <Formik
                                initialValues={category}
                                enableReinitialize
                                validationSchema={categorySchema}
                                onSubmit={async (values,{ setSubmitting, setErrors }) => {
                                    const formData = new FormData();
                                    formData.append('id',values.id);
                                    formData.append('_method','PUT');
                                    formData.append('category_name',values.category_name);

                                    try {
                                        const res = await dispatch(updateCategory(formData));
                                        if (res.payload.success) {
                                            navigate('/category');
                                        } else {
                                            setNotificationData({
                                                variant: 'danger',
                                                message: res.payload.message,
                                                show: true
                                            });
                                            setErrors(res.payload.data);
                                            setTimeout(() => {
                                                setNotificationData({variant: "", message: "", show: false});
                                            }, process.env.REACT_APP_NOTIFICATION_TIME);
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({errors, touched, setFieldValue}) => (
                                    <Form method="post">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="category-name">Category Name</label>
                                                <Field type="text" name="category_name" className="form-control" id="category-name" placeholder="Enter Category Name" />
                                                {errors.category_name && touched.category_name ? (<span className="text-danger">{errors.category_name}</span>) : null}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary mr-5">Submit</button>
                                            <NavLink className="btn btn-secondary" to="/category">Cancel</NavLink>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
};

export default Edit;